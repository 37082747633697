<template>
    <!-- <GoogleMap   ref="mapRef" id='maph' api-key="AIzaSyA62nfiBf_7XngEJxzZxltpRMbJjd2f7Fg" style="width: 100%; height: 95%;" :center="center1"  :zoom="15"> -->
		<GoogleMap   ref="mapRef" id='maph' api-key="AIzaSyC-8ib8PMibUDDYoj5kzRzKwcusvw3Gztc" style="width: 100%; height: 95%;" :center="center1"  :zoom="15">
				 
            <Marker :options="{ position: center1 }"  >
						    <!-- <Polyline :options="polypath" /> -->

					</Marker>
          
          <Polyline v-if="polyline.length>0"  :options="polypath" :setPath="polyline" />
           <!-- <GMapPolyline 
      :path="polyline"
      :editable="true"
      ref="polyline" /> -->

              <!-- <GMapMarker
      :position="center1"
      :clickable="true"
      :draggable="true"
    /> -->

		
		  
  </GoogleMap>
</template>

<script>
import { GoogleMap, Marker,MarkerCluster,InfoWindow,GMapMarker,Polyline } from "vue3-google-map";

export default {
components:{
		GoogleMap, Marker,MarkerCluster,InfoWindow,GMapMarker,Polyline
    }
}
</script>